<template>
  <div class="legal-area">
    <HeroImage :bg-image="'images/2-service-pages-hero.jpg'" :has-purple-bg="false">
      <div class="hero-title has-text-centered">
        <h3 class="title is-2 pt-6 txt-white">
          Área legal
        </h3>
      </div>
    </HeroImage>
    <section class="over-hero">
      <div class="container is-fullhd">
        <div class="my-account-wrap">
          <div class="columns content-my-account-wrap">
            <div class="column is-4 has-background-light sidebar-menu">
              <div class="sidebar">
                <button class="sidebar-btn selected">
                  <div class="sidebar-btn-label hide-mobile"><label>Política de privacidad</label></div>
                </button>
                <button class="sidebar-btn">
                  <div class="sidebar-btn-label hide-mobile"><label>Politica de los cookies</label></div>
                </button>
                <button class="sidebar-btn">
                  <div class="sidebar-btn-label hide-mobile"><label>Terminos de usos</label></div>
                </button>
                <button class="sidebar-btn">
                  <div class="sidebar-btn-label hide-mobile"><label>Política de devoluciones</label></div>
                </button>
              </div>
              <div class="sidebar-mobile-wrap">
                <button class="step-btn" @click="openStepWrap()" >
                  <label>Política de privacidad</label>
                  <span class="icon-chevron-down" v-if="!mobileStepOpen"></span>
                  <span class="icon-chevron-up" v-if="mobileStepOpen"></span>
                </button>
                <transition name="fade">
                  <div class="step-wrap" v-if="mobileStepOpen">
                    <router-link :to="'/legal-area/privacy-policy'" exact>Política de privacidad</router-link>
                    <router-link :to="'/legal-area/cookies-policy'" exact>Politica de los cookies</router-link>
                    <router-link :to="'/legal-area/terms-of-use'" exact>Terminos de usos</router-link>
                    <router-link :to="'/legal-area/return-policy'" exact>Política de devoluciones</router-link>
                  </div>
                </transition>
              </div>
            </div>

            <div class="column is-8 right-column">
              <h3 class="title is-4 pb-4 txt-c-mobile">Política de privacidad</h3>
              <p>
                Maecenas sed diam eget risus varius blandit sit amet non magna. Nullam quis risus eget urna mollis ornare vel eu leo. Etiam porta sem malesuada magna mollis euismod. Vestibulum id ligula porta felis euismod semper. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Sed posuere consectetur est at lobortis. Sed posuere consectetur est at lobortis.
              </p>
              <p>
                <strong>Donec sed odio dui.</strong><br>
                Donec sed odio dui. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nullam id dolor id nibh ultricies vehicula ut id elit. Sed posuere consectetur est at lobortis.
              </p>
              <ul>
                <li>• Donec id elit non mi porta gravida at eget metus.</li>
                <li>• Curabitur blandit tempus porttitor.</li>
                <li>• Integer posuere erat a ante venenatis dapibus posuere velit aliquet.</li>
              </ul>
              <p>
                Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Aenean lacinia bibendum nulla sed consectetur. Nullam quis risus eget urna mollis ornare vel eu leo. Praesent commodo cursus magna, vel scelerisque nisl consectetur et.
              </p>
              <p>
                Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Maecenas sed diam eget risus varius blandit sit amet non magna. Maecenas sed diam eget risus varius blandit sit amet non magna. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Donec sed odio dui.
              </p>
              <ol>
                <li>Donec id elit non mi porta gravida at eget metus.</li>
                <li>Curabitur blandit tempus porttitor.</li>
                <li>Integer posuere erat a ante venenatis dapibus posuere velit aliquet.</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import HeroImage from '@/components/HeroImage.vue'
import Footer from '@/components/Footer.vue'
export default {
  name: 'LegalArea',
  components: {
    HeroImage,
    Footer
  },
  data () {
    return {
      mobileStepOpen: false
    }
  },
  methods: {
    openStepWrap () {
      this.mobileStepOpen = !this.mobileStepOpen
    },
    goTo (path) {
      this.$router.push({ path: path })
    }
  }
}
</script>

<style scoped lang="scss">
.legal-area{
  width: 100%;
  section{
    width: 100%;
    padding: 0px;
    &.over-hero{
      position: relative;
      margin-top: -310px;
      margin-bottom: 80px;
      z-index: 100;
    }
    .my-account-wrap{
      width: 100%;
      border-radius: 4px;
      background-color: #FFFFFF;
      box-shadow: 0 2px 24px 0 rgba(0,0,0,0.2);
      .content-my-account-wrap{
        padding: 0px;
        .has-background-light{
          border-radius: 4px 0px 0px 4px;
        }
        .sidebar-menu{
          .sidebar-mobile-wrap{
            display: none;
          }
          .sidebar{
            padding-top: 78px;
            padding-bottom: 78px;
            padding-left: 31px;
            padding-right: 28px;
            .sidebar-btn{
              position: relative;
              width: 100%;
              height: 50px;
              border: 0px;
              margin-bottom: 14px;
              background-color: transparent;
              display: flex;
              justify-content: space-between;
              &.selected{
                // border-right: 2px solid #E3914F;
                .sidebar-btn-label{
                  label{
                    color: #E3914F;
                    }
                }
                &:after{
                  content: "";
                  display: block;
                  width: 2px;
                  height: 100%;
                  position: absolute;
                  top: 0px;
                  right: -40px;
                  background-color: #E3914F;
                }
              }
              .sidebar-btn-label{
                position: relative;
                height: 100%;
                width: calc(100% - 14px);
                text-align: left;
                label{
                  width: 100%;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  color: #57567C;
                  text-transform: uppercase;
                  font-weight: 600;
                }
              }
            }
          }
        }
        .right-column{
          padding: 80px;
          p{
            padding-bottom: 21px;
          }
          ol{
            padding-left: 21px;
            padding-bottom: 21px;
          }
          ul{
            padding-left: 14px;
            padding-bottom: 21px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .legal-area{
    section{
      .my-account-wrap{
        .content-my-account-wrap{
          .right-column{
            padding: 35px;
          }
          .sidebar-menu{
            width: 100%;
            .sidebar-mobile-wrap{
              position: relative;
              display: block;
              height: 66px;
              width: 100%;
              .step-btn{
                display: block;
                height: 100%;
                width: 100%;
                max-width: 320px;
                border: 0px;
                margin: 0 auto;
                background-color: transparent;
                color: #E3914F;
                text-transform: uppercase;
                font-size: 16px;
                font-weight: bold;
                span{
                  position: absolute;
                  top: 50%;
                  right: 21px;
                  transform: translate(0%, -50%);
                  font-size: 1.5rem;
                }
              }
              .step-wrap{
                display: block;
                width: 100%;
                background-color: #F6F4EF;
                position: absolute;
                top: 100%;
                left: 0px;
                z-index: 99999;
                a{
                  position: relative;
                  display: block;
                  height: 66px;
                  width: 100%;
                  max-width: 320px;
                  margin: 0 auto;
                  text-transform: uppercase;
                  font-size: 16px;
                  font-weight: bold;
                  text-align: center;
                  line-height: 66px;
                  &:after{
                    content: "";
                    display: block;
                    position: absolute;
                    bottom: 0px;
                    left: 50%;
                    transform: translate(-50%, 0);
                    width: 0%;
                    height: 2px;
                    background-color: #E3914F;
                    transition: all .3s ease-out;
                  }
                  &.router-link-active{
                    &:after{
                      width: 50%;
                    }
                  }
                }
              }
            }
            .sidebar{
              display: none;
            }
          }
        }
      }
    }
  }
}
</style>
